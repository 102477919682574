import LavieRoom from './LavieRoom';
import Global from './utils/Global';
import EventCenter from './event/EventCenter';
import EventType from './event/EventType';
import NetService from './utils/NetService';
import LavieEvent from './event/LavieEvent';
import NetUtils, { IPostParams } from './utils/NetUtils';

let lavieRoom = new LavieRoom();

export const MESSAGE_TYPE = {
    SOCKET_STATE: EventType.TYPE_SOCKET_STATE,
    ROOM_MESSAGE: EventType.TYPE_ROOM_MESSAGE
};

export const ROOM_MESSAGE = {
    MSG_USER_LOGIN_SUCCESS: LavieEvent.USER_LOGIN_SUCCESS,
    MSG_USER_ENTER: LavieEvent.ROOM_USER_ENTER,
    MSG_USER_CHAT: LavieEvent.ROOM_CHAT_MESSAGE,
    MSG_USER_LIST: LavieEvent.ROOM_USERS_LIST,
    MSG_UPDATE_MIC: LavieEvent.ROOM_UPDATE_MIC,
    MSG_PICK_STATE: LavieEvent.ROOM_PICK_STATUS,
    MSG_ROOM_TYPE_CHANGED: LavieEvent.ROOM_TYPE_CHANGED,
    MSG_USER_TIME_LIMIT: LavieEvent.ROOM_TIME_LIMIT
};

export const ERROR_CODE = {
    ERR_DEVICE_NULL: 4400001,
    ERR_ROOMID_NULL: 4400002,
    ERR_ROOMID_ERROR: 4400003,
    ERR_CHATMSG_NULL: 4400004,
    ERR_MSGTYPE_NULL: 4400005,
    ERR_RESPONSE_NULL: 4400006,
    ERR_QUERY_AGORA_FAILD: 4400007,
}
 
/**
 * SDK初始化。
 * @param options 
 */
export function lavieSdkInit(options?: any) {
    if (Global.CHECKNULL(lavieRoom) === true) {
        lavieRoom = new LavieRoom();
    }
    LavieRoom.config = options;
    //初始化
    const response: any = lavieRoom.onInit();
    if (response.code <= 0) {
        Global.debugLog('LavieSDK init response: ', response);
        return false;
    }
    console.log('LavieSDK init success! version=', Global.APP_VERSION);
    return true;
}
 
/**
 * 发起post请求
 * @param params 入参
 * @returns Promise
 */
export async function post(params:IPostParams){
    // const params:IPostParams = {
    //     appId:appid,
    //     module:module,
    //     method:method,
    //     data:$params
    // };
    const response: any = await NetUtils.post(params);
    return response;
}

export async function accountLoginHandle(authData:any, device:string) {
    if (Global.CHECKNULL(authData)) {
        return { code: ERROR_CODE.ERR_RESPONSE_NULL, message: 'Login failed! The auth user info cannot be empty. ' };
    }
    const userData:any = {...authData};
    userData.device = device;
    lavieRoom.loginSuccessHandle(userData);
}

//用户（设备号）注册/登录
export async function asyncLoginHandle(device: string) {
    if (Global.CHECKNULL(device) === true) {
        return { code: ERROR_CODE.ERR_DEVICE_NULL, message: 'Login failed! The device number cannot be empty. ' };
    }
    const response: any = await NetService.thirdAuth(device);
    if (Global.CHECKNULL(response) === true) {
        return response;
    }
    if (response.code !== 200) {
        return response;
    }
    const mydata: any = response.data;
    if (Global.CHECKNULL(mydata) === false) {
        mydata.device = device;
        lavieRoom.loginSuccessHandle(mydata);
    }
    return response;
}

//获取直播房间列表
export async function queryPopularRoomList(pageNo: number = 1) {
    const response: any = await NetService.queryPopularRoomList(pageNo);
    return response;
}

//进入直播房间
export async function enterLiveRoom($roomId: number, $password: string = '') {
    if (Global.CHECKNULL($roomId) === true) {
        return { code: ERROR_CODE.ERR_ROOMID_NULL, message: 'Enter room failed! The room id cannot be empty. ' };
    }
    if ($roomId <= 0) {
        return { code: ERROR_CODE.ERR_ROOMID_ERROR, message: 'Enter room failed! The room id error. ' };
    }
    const response: any = await NetService.enterLiveRoom($roomId, $password);
    return response;
}

//离开直播房间
export async function leaveLiveRoom($roomId: number) {
    if (Global.CHECKNULL($roomId) === true) {
        return { code: ERROR_CODE.ERR_ROOMID_NULL, message: 'Leave room failed! The room id cannot be empty. ' };
    }
    if ($roomId <= 0) {
        return { code: ERROR_CODE.ERR_ROOMID_ERROR, message: 'Leave room failed! The room id error. ' };
    }
    const response: any = await NetService.leaveLiveRoom($roomId);
    return response;
}

//发送聊天信息
export async function sendPublicChatMsg($roomId: number, $msg: string) {
    if (Global.CHECKNULL($roomId) === true) {
        return { code: ERROR_CODE.ERR_ROOMID_NULL, message: 'Send msg failed! The room id cannot be empty. ' };
    }
    if ($roomId <= 0) {
        return { code: ERROR_CODE.ERR_ROOMID_ERROR, message: 'Send msg failed! The room id error. ' };
    }
    if (Global.CHECKNULL($msg) === true) {
        return { code: ERROR_CODE.ERR_CHATMSG_NULL, message: 'Send msg failed! The msg cannot be empty. ' };
    }
    const usinfo = lavieRoom.getLoginUser();
    const response: any = await NetService.sendPublicChatMsg($roomId, $msg, usinfo);
    return response;
}

//查询麦位信息
export async function queryRoomSeatInfoList($roomId: number) {
    if (Global.CHECKNULL($roomId) === true) {
        return { code: ERROR_CODE.ERR_ROOMID_NULL, message: 'Send msg failed! The room id cannot be empty. ' };
    }
    if ($roomId <= 0) {
        return { code: ERROR_CODE.ERR_ROOMID_ERROR, message: 'Send msg failed! The room id error. ' };
    }

    const response: any = await NetService.queryRoomSeatInfoList($roomId);
    return response;
}

//主动拉取用户列表
export async function queryOnlineRoomUserList($roomId: number, $pageNo: number = 1) {
    if (Global.CHECKNULL($roomId) === true) {
        return { code: ERROR_CODE.ERR_ROOMID_NULL, message: 'Send msg failed! The room id cannot be empty. ' };
    }
    if ($roomId <= 0) {
        return { code: ERROR_CODE.ERR_ROOMID_ERROR, message: 'Send msg failed! The room id error. ' };
    }

    const response: any = await NetService.queryOnlineRoomUserList($roomId, $pageNo);
    return response;
}

//主动拉取用户列表
export async function queryLiveAgoraAppId() {
    const response: any = await NetService.queryLiveAgoraAppId();
    if (Global.CHECKNULL(response) === true) {
        return { code: ERROR_CODE.ERR_RESPONSE_NULL, message: 'query agora appid error' };
    }
    if (response.code !== 200) {
        return { code: ERROR_CODE.ERR_QUERY_AGORA_FAILD, message: 'query agora appid failed' };
    }
    const datalist = (Global.CHECKNULL(response.data) === true) ? [] : response.data;
    if (Global.CHECKNULL(datalist) === true) {
        return { code: ERROR_CODE.ERR_QUERY_AGORA_FAILD, message: 'query agora appid is null' };
    }
    if (datalist.length <= 0) {
        return { code: ERROR_CODE.ERR_QUERY_AGORA_FAILD, message: 'query agora appid is empty' };
    }
    const agoraId = datalist[0].value;
    return { code: 200, data: { agoraAppId: `${agoraId}` } };
}

/**
 * 订阅相关消息
 * @param type 
 * @param onMessage 
 */
export function addMsgListener(type: string, onMessage: (e: any) => void) {
    if (Global.CHECKNULL(type) === true) {
        return { code: ERROR_CODE.ERR_MSGTYPE_NULL, message: 'Add listener failed! The message type cannot be empty. ' };
    }
    const eventId = EventCenter.getInstance().addEventListener(type, onMessage);
    return eventId;
}

/**
 * 移除消息订阅
 * @param type 
 */
export function removeMsgListener(eventId: number) {
    if (Global.CHECKNULL(eventId) === true) {
        return;
    }
    if (eventId <= 0) { return; }
    EventCenter.getInstance().removeEventById(eventId);
}


