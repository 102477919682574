/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

// var $protobuf = require("protobufjs/minimal");
// var $protobuf = require("protobufjs");
var $protobuf = lib_protobuf;

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.debuf = (function() {

    /**
     * Namespace debuf.
     * @exports debuf
     * @namespace
     */
    var debuf = {};

    debuf.ProtobufData = (function() {

        /**
         * Properties of a ProtobufData.
         * @memberof debuf
         * @interface IProtobufData
         * @property {string|null} [cmd] ProtobufData cmd
         * @property {string|null} [msg] ProtobufData msg
         * @property {number|null} [code] ProtobufData code
         * @property {string|null} [errMsg] ProtobufData errMsg
         */

        /**
         * Constructs a new ProtobufData.
         * @memberof debuf
         * @classdesc Represents a ProtobufData.
         * @implements IProtobufData
         * @constructor
         * @param {debuf.IProtobufData=} [properties] Properties to set
         */
        function ProtobufData(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProtobufData cmd.
         * @member {string} cmd
         * @memberof debuf.ProtobufData
         * @instance
         */
        ProtobufData.prototype.cmd = "";

        /**
         * ProtobufData msg.
         * @member {string} msg
         * @memberof debuf.ProtobufData
         * @instance
         */
        ProtobufData.prototype.msg = "";

        /**
         * ProtobufData code.
         * @member {number} code
         * @memberof debuf.ProtobufData
         * @instance
         */
        ProtobufData.prototype.code = 0;

        /**
         * ProtobufData errMsg.
         * @member {string} errMsg
         * @memberof debuf.ProtobufData
         * @instance
         */
        ProtobufData.prototype.errMsg = "";

        /**
         * Creates a new ProtobufData instance using the specified properties.
         * @function create
         * @memberof debuf.ProtobufData
         * @static
         * @param {debuf.IProtobufData=} [properties] Properties to set
         * @returns {debuf.ProtobufData} ProtobufData instance
         */
        ProtobufData.create = function create(properties) {
            return new ProtobufData(properties);
        };

        /**
         * Encodes the specified ProtobufData message. Does not implicitly {@link debuf.ProtobufData.verify|verify} messages.
         * @function encode
         * @memberof debuf.ProtobufData
         * @static
         * @param {debuf.IProtobufData} message ProtobufData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProtobufData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cmd != null && Object.hasOwnProperty.call(message, "cmd"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.cmd);
            if (message.msg != null && Object.hasOwnProperty.call(message, "msg"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.msg);
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.code);
            if (message.errMsg != null && Object.hasOwnProperty.call(message, "errMsg"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.errMsg);
            return writer;
        };

        /**
         * Encodes the specified ProtobufData message, length delimited. Does not implicitly {@link debuf.ProtobufData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof debuf.ProtobufData
         * @static
         * @param {debuf.IProtobufData} message ProtobufData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProtobufData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProtobufData message from the specified reader or buffer.
         * @function decode
         * @memberof debuf.ProtobufData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {debuf.ProtobufData} ProtobufData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProtobufData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.debuf.ProtobufData();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.cmd = reader.string();
                    break;
                case 2:
                    message.msg = reader.string();
                    break;
                case 3:
                    message.code = reader.int32();
                    break;
                case 4:
                    message.errMsg = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProtobufData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof debuf.ProtobufData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {debuf.ProtobufData} ProtobufData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProtobufData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProtobufData message.
         * @function verify
         * @memberof debuf.ProtobufData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProtobufData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.cmd != null && message.hasOwnProperty("cmd"))
                if (!$util.isString(message.cmd))
                    return "cmd: string expected";
            if (message.msg != null && message.hasOwnProperty("msg"))
                if (!$util.isString(message.msg))
                    return "msg: string expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isInteger(message.code))
                    return "code: integer expected";
            if (message.errMsg != null && message.hasOwnProperty("errMsg"))
                if (!$util.isString(message.errMsg))
                    return "errMsg: string expected";
            return null;
        };

        /**
         * Creates a ProtobufData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof debuf.ProtobufData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {debuf.ProtobufData} ProtobufData
         */
        ProtobufData.fromObject = function fromObject(object) {
            if (object instanceof $root.debuf.ProtobufData)
                return object;
            var message = new $root.debuf.ProtobufData();
            if (object.cmd != null)
                message.cmd = String(object.cmd);
            if (object.msg != null)
                message.msg = String(object.msg);
            if (object.code != null)
                message.code = object.code | 0;
            if (object.errMsg != null)
                message.errMsg = String(object.errMsg);
            return message;
        };

        /**
         * Creates a plain object from a ProtobufData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof debuf.ProtobufData
         * @static
         * @param {debuf.ProtobufData} message ProtobufData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProtobufData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.cmd = "";
                object.msg = "";
                object.code = 0;
                object.errMsg = "";
            }
            if (message.cmd != null && message.hasOwnProperty("cmd"))
                object.cmd = message.cmd;
            if (message.msg != null && message.hasOwnProperty("msg"))
                object.msg = message.msg;
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.errMsg != null && message.hasOwnProperty("errMsg"))
                object.errMsg = message.errMsg;
            return object;
        };

        /**
         * Converts this ProtobufData to JSON.
         * @function toJSON
         * @memberof debuf.ProtobufData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProtobufData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ProtobufData;
    })();

    return debuf;
})();

module.exports = $root;
