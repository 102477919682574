import Global from "./Global";
import MyUtils from "./MyUtils";
import { Md5 } from 'ts-md5';
// import { Toast } from 'antd-mobile';

export interface IPostParams {
    appId: string;
    module: string;
    method: string;
    data: any;
};

class NetUtils {

    static LOGIN_APPID: string = '3abaf680-b83d-4777-845c-999d7a6e54d4';
    static LOGIN_USERID: string = '';
    static LOGIN_TOKEN: string = '';
    static LOGIN_ROOMID: number = 0;
    static ACTIVITY_ID: number = 0;
    static TEST_USERID: string = '';

    static loginInit() {
        NetUtils.LOGIN_APPID = MyUtils.getUrlParams('appId');
        NetUtils.LOGIN_USERID = MyUtils.getUrlParams('userId');
        NetUtils.LOGIN_TOKEN = MyUtils.getUrlParams('token');
        const roomId: string = MyUtils.getUrlParams('roomId');
        if (MyUtils.isNull(roomId) && roomId !== 'null') {
            NetUtils.LOGIN_ROOMID = parseInt(roomId);
        }
        MyUtils.DEBUG = MyUtils.isDebugEnvironment();
        MyUtils.API_HOST = MyUtils.getApiDomain();
    }

    static async post(postParams: IPostParams) {
        if (MyUtils.isNull(NetUtils.LOGIN_APPID) || MyUtils.isNull(NetUtils.LOGIN_USERID)) {
            NetUtils.loginInit();
        }
        if (MyUtils.isNull(NetUtils.LOGIN_TOKEN)) {
            NetUtils.loginInit();
        }
        const mydata: any = MyUtils.isNull(postParams.data) ? {} : postParams.data;
        return new Promise((resolve, reject) => {
            const params: any = {
                baseParams: {
                    appId: NetUtils.LOGIN_APPID,
                    lang: 'en',
                },
                business: { ...mydata },
                userAuth: {
                    appId: NetUtils.LOGIN_APPID,
                    userId: NetUtils.LOGIN_USERID,
                    token: NetUtils.LOGIN_TOKEN
                }
            }
            let request = new XMLHttpRequest();
            const signParams = {
                module: `${postParams.module}`,
                method: `${postParams.method}`,
                timestamp: (new Date()).valueOf(),
                rpcType: 'dubbo',
                content: JSON.stringify(params)
            }
            //生成签名
            const sortedData: any = {};
            let keys = Object.keys(signParams);
            keys.sort();
            keys.forEach((key) => {
                sortedData[key] = params[key];
            });
            //参数排序
            let keylist = Object.keys(signParams);
            let myValues = '';
            let mySignStr = '';
            keylist.forEach((key) => {
                myValues = sortedData[key];
                mySignStr = mySignStr.concat(`${key}${myValues}`);
            });
            const md5SignStr = mySignStr.concat(`a5aa7d38-34e6-4115-bdf5-f4ea0c98ad66`);
            // const appSign = `${MD5(md5SignStr)}`;
            const appSign = Md5.hashStr(md5SignStr);
            const myAppSign = appSign.toLocaleUpperCase();

            MyUtils.API_HOST = MyUtils.getApiDomain();
            console.log('NetUtils::MyUtils.API_HOST------>', MyUtils.API_HOST);

            request.open("POST", MyUtils.API_HOST, true);
            request.setRequestHeader('Content-Type', 'application/json');
            request.setRequestHeader('module', signParams.module);
            request.setRequestHeader('appId', `${postParams.appId}`);
            request.setRequestHeader('appVersion', '1');
            request.setRequestHeader('appkey', 'hayya-eu');
            request.setRequestHeader('method', signParams.method);
            request.setRequestHeader('rpcType', signParams.rpcType);
            request.setRequestHeader('sign', myAppSign);
            request.setRequestHeader('timestamp', `${signParams.timestamp}`);
            request.onerror = () => {
                // Toast.show({ content: 'request error' });
            }
            request.onreadystatechange = () => {
                if (request.readyState == 4 && (request.status >= 200 && request.status < 400)) {
                    const response = request.responseText;
                    let dataJson = null;
                    try {
                        dataJson = JSON.parse(response);
                    } catch (e) {
                        // Toast.show({ content: 'data error' });
                        reject({ code: -1, message: 'The data parse error' });
                        return;
                    }
                    if (dataJson?.code !== 200) {
                        // Toast.show({ content: `${dataJson.message}` });
                    } else {
                        // Toast.clear();
                    }
                    resolve(dataJson);
                }
            };
            // Toast.show({ icon: 'loading', maskClickable: true });
            request.send(JSON.stringify(params));
        });
    }
}

export default NetUtils;