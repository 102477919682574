class LavieEvent {

    public static EVENT_SOCKET_OPENED:number = 880000;
    public static EVENT_SOCKET_CLOSED:number = 880001;
    public static EVENT_SOCKET_MESSGE:number = 880002;
    public static EVENT_HEART_TIMEOUT:number = 880003;
    public static EVENT_SOCKET_ERROR:number = 880004;

    public static USER_LOGIN_SUCCESS:number = 8001;
    public static ROOM_USER_ENTER:number = 2201;
    public static ROOM_UPDATE_MIC:number = 2302;//刷新麦位消息
    public static ROOM_CHAT_MESSAGE:number = 2304;
    public static ROOM_USERS_LIST:number = 2301;
    public static ROOM_PICK_STATUS:number = 2321;//PK状态改变
    public static ROOM_TYPE_CHANGED:number = 103041008;//房间类型改变
    public static ROOM_TIME_LIMIT:number = 103041024;//用户在房时长限制

}

export default LavieEvent