
import {Md5} from 'ts-md5';

class Global {

    static DEBUG = false;

    static API_DOMAIN = Global.DEBUG ? '//api-qa.hayyaapp.com' : 'https://api.vidovideo.com'; 
    static WSS_HOST = Global.DEBUG ? 'ws-qa.girlsmm.com' : 's.vidovideo.com';
    static WSS_PORT = Global.DEBUG ? 5566 : 0; 
    static AUTO_WSS = true; //是否自动适配wss协议

    static SIGN_APPKEY = Global.DEBUG ? 'vivalive-qa' : 'hayya-eu';
    static BODY_APPKEY = '11000101'; 
    static APP_DEVICE = '4c97a712e41ee1e9';
    static PUBLIC_KEY = 'b876405a8964661c';
    static SIGN_SECRETKEY = Global.DEBUG ? 'vivalive-qa' : 'vivalive';
    static SING_APPSECRET = Global.DEBUG ? 'vivalive-qa' : 'vivalive';

    static LOGIN_PRODID = 305;
    static LOGIN_APPID = Global.DEBUG ? '3abaf680-b83d-4777-845c-999d7a6e54d4':'11540c18-9843-48d8-9ef4-e6266ec8972f';
    static LOGIN_TOKEN = '';
    static LOGIN_USERID = '';
    static LOGIN_DEVICE = '';
    static LOGIN_UID = 0;
    static LANGUAGE = 'en';
    static APP_VERSION = '1.0.9';

    static debugLog(msg:any, data:any = null) {
        if (Global.DEBUG === false) {
            return;
        }
        if (data != null) {
            console.log(`LavieSDK-->${msg}`, data);
            return;
        }
        console.log(`LavieSDK:LOG-->${msg}`);
    }

    static loginInit = function () {
        Global.LOGIN_APPID = this.getUrlParams('appId');
        Global.LOGIN_USERID = this.getUrlParams('userId');

        const loginToken = this.getUrlParams('token');
        const mytoken = (loginToken === null || loginToken === undefined) ? '0' : loginToken;

        const decodeToken = decodeURIComponent(mytoken);

        const decodeEnabled = (loginToken === decodeToken) ? false : true;
        Global.LOGIN_TOKEN = (decodeEnabled === true) ? decodeToken : mytoken;

        // Global.API_DOMAIN = Global.getApiHost();
        console.log('Global.LOGIN_TOKEN-loginInit----->', Global.API_DOMAIN, loginToken, decodeToken, Global.LOGIN_TOKEN);
    }

    //空值校验 | 默认值
    static ParseNull = function (item:any, mark = '--') {
        let myValue = `${mark}`;
        if (item === null || item === undefined || item === '') {
            return myValue;
        }
        myValue = item;
        return myValue;
    };

    //是否为空
    static CHECKNULL = function (item:any) {
        if (item === null || item === undefined || item === '') {
            return true;
        }
        return false;
    }

    //获取url参数
    static getUrlParams = function (par:any) {
        if (par == '')
            return null;
        var reg = new RegExp("(^|&)" + par + "=([^&]*)(&|$)");
        var r = window.location.search.substr(1).match(reg);
        if (r != null)
            return r[2];

        return null;
    }

    static getRequestSign = function(){
        let mySignStr = `${Global.LOGIN_APPID}${Global.SING_APPSECRET}`;
        const appSign = Md5.hashStr(mySignStr);
        const myAppSign = appSign.toLocaleUpperCase();
        Global.debugLog('Global::APP签名生成签名-->', myAppSign);

        return myAppSign;
    }

    //根据参数列表生成接口签名
    static getReqAppSign = function (params:any) {
        if (params == undefined || params == '') {
            return '';
        }
        const sortedData:any = Global.ksort(params);
        if (sortedData == undefined || sortedData == '') {
            return '';
        }
        // console.log('APP签名-参数列表-->', sortedData);
        let keylist = Object.keys(sortedData);
        let myvalue = '';
        let mySignStr = '';
        keylist.forEach((key) => {
            myvalue = sortedData[key];
            mySignStr = mySignStr.concat(`${key}${myvalue}`);
        })
        const md5SignStr = mySignStr.concat(`${Global.SING_APPSECRET}`);
        // console.log('APP签名字符串-->', md5SignStr);
  
        // const appSign = CryptoJS.MD5(md5SignStr);
        const appSign = Md5.hashStr(md5SignStr);
        const myAppSign = appSign.toLocaleUpperCase();
        // console.log('APP签名生成签名-->', myAppSign);

        return myAppSign;
    }

    static ksort = function (params:any) {
        let sorted:any = {};
        let keys = Object.keys(params);
        keys.sort();
        keys.forEach((key) => {
            sorted[key] = params[key];
        })
        return sorted;
    }

    //end all
}

export default Global;