
 class LavieCmd {

    static REQ_USER_LOGIN:string = '0001';
    static REQ_HEART_SEND:string = '0002';

    static RESP_ROOM_DATA:string = '0999';

    static CMD_LOGIN_SUCCESS:number = 8001;
    static CMD_HEART_PACKAGE:number = 8002;

}

export default LavieCmd