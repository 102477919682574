export default class BaseSocket {

    protected _webSocket:any = null;
    protected _errorCount = 0;
    protected _closeCount = 0;

    connet(data: {
        url: string,
        onOpen?: (e: Event) => void,
        onMessage?: (e: MessageEvent) => void,
        onClose?: (e: CloseEvent) => void,
        onError?: (e: Event) => void,
    }) {
        if (this.isAlive()) {
            console.warn("must close existed socket before connet");
            return;
        }
        if (!data.url) {
            console.warn("socket url can't empty");
            return;
        }
        this._webSocket = new WebSocket(data.url);
        this._webSocket.binaryType = "arraybuffer";
        this._webSocket.onopen = data.onOpen;
        this._webSocket.onmessage = (e:any) => {
            if (!this._webSocket) {
                console.warn("socket should be closed alreay");
                return;
            }
            data.onMessage && data.onMessage(e);
        }
        this._webSocket.onclose = (e:any) => {
            this._closeCount++;
            console.log("socket close:", this._closeCount, e);
            if (!this._webSocket) {
                return;
            }
            this._webSocket = null;
            data.onClose && data.onClose(e);
        };
        this._webSocket.onerror = (e:any) => {
            this._errorCount++;
            console.warn("socket connect error:", this._errorCount, e);
            if (!this._webSocket) {
                return;
            }
            let s = this._webSocket;
            this._webSocket = null;
            s.close();
            data.onError && data.onError(e);
        };
    }

    close(code?: number, reason?: string) {
        if (!this._webSocket) {
            return;
        }

        this._webSocket.close(code, reason);
        this._webSocket = null;
    }

    send(data:any) {
        if (!this.isAlive()) {
            console.warn("socket is closed or error, can't send data");
            return false;
        }

        this._webSocket.send(data);
        return true;
    }

    getStatus() {
        if (this._webSocket) {
            return this._webSocket.readyState;
        }

        return WebSocket.CLOSED;
    }

    isAlive() {
        return this._webSocket && (this._webSocket.readyState == WebSocket.CONNECTING || this._webSocket.OPEN);
    }
}