import Global from "../../utils/Global";

export default class DataBody {
     
    public msgId: any = -1; 
    public data: any = null;

    public parseJson(jsonStr: string) {
        if (Global.CHECKNULL(jsonStr)===true) {
            this.data = null;
            return;
        }
        let dataObj = null;
        try {
            dataObj = JSON.parse(jsonStr);
        } catch (e) {
            dataObj = null;
            console.log('DataBody::JSON parse error!');
        }
        if (dataObj !== null) {
            this.data = dataObj;
        }
    }
}