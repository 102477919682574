import Global from "../utils/Global";

class EventCenter {

    private static instance: EventCenter;

    static getInstance() {
        if (Global.CHECKNULL(EventCenter.instance) === true) {
            EventCenter.instance = new EventCenter();
        }
        return EventCenter.instance;
    }

    lavieEventMap: any = {};
    curEventId: number = 52088000;

    addEventListener(type: string, $callback?: (e: any) => void) {
        if (Global.CHECKNULL(type) === true) {
            return -1;
        }
        if (Global.CHECKNULL($callback) === true) {
            return -1;
        }
        const myevents = this.lavieEventMap[`${type}`];
        const callbackList = (Global.CHECKNULL(myevents) === true) ? [] : myevents;
        const countmax = callbackList.length;
        if (countmax > 0) {
            //移除原回调
            this.removeEventListener(type, $callback);
        }
        //加入回调队列
        this.curEventId += 1;
        callbackList.push({
            eventId: this.curEventId,
            callback: $callback
        });
        this.lavieEventMap[`${type}`] = callbackList;
        Global.debugLog('EventCenter::event queue count-->', this.lavieEventMap);
        return this.curEventId;
    }

    removeEventById(eventId: number) {
        const myEeventMap = this.lavieEventMap;
        const myKeyList: any[] = Object.keys(myEeventMap);
        myKeyList.map((key: any, index: number) => {
            const myevents = myEeventMap[`${key}`];
            const callbackList = (Global.CHECKNULL(myevents) === true) ? [] : myevents;
            const countmax = callbackList.length;
            for (var i = 0; i < countmax; i++) {
                const callInfo = callbackList[i];
                if (callInfo.eventId === eventId) {
                    callbackList.splice(i, 1);
                    Global.debugLog('移除消息监听-myEeventMap------>', this.lavieEventMap);
                    break;
                }
            }
        });
    }

    removeEventListener(type: string, $callback?: (e: any) => void) {
        if (Global.CHECKNULL(type) === true) {
            return;
        }
        if (Global.CHECKNULL($callback) === true) {
            return;
        }
        const myevents = this.lavieEventMap[`${type}`];
        const callbackList = (Global.CHECKNULL(myevents) === true) ? [] : myevents;
        const countmax = callbackList.length;
        if (countmax <= 0) { return; }

        for (var i = 0; i < countmax; i++) {
            const callInfo = callbackList[i];
            if (callInfo.callback === $callback) {
                callbackList.splice(i, 1);
                break;
            }
        }
        this.lavieEventMap[`${type}`] = callbackList;
    }

    dispatchEvent(type: string, $msgId: number, $data?: any) {
        const myevents = this.lavieEventMap[`${type}`];
        const callbackList = (Global.CHECKNULL(myevents) === true) ? [] : myevents;
        callbackList.map((item: any, index: number) => {
            const callbackFunc = item.callback;
            if (callbackFunc) {
                const evtInfo: any = {
                    eventType: type,
                    msgId: $msgId,
                    eventId: item.eventId,
                    data: $data,
                }
                callbackFunc(evtInfo);
            }
        });
    }

}

export default EventCenter;