import DataBody from "./DataBody";
import LavieSocket from "../LavieSocket";
import LavieCmd from "./LavieCmd";
import Global from "../../utils/Global";

export default class LavieData {

    static LOGIN_APPID = '';
    static LOGIN_USERID = '';
    static LOGIN_TOKEN = '';

    static login(mydata: DataBody) {
        if (Global.CHECKNULL(mydata)===true) {
            return false;
        }
        const mycmd = (Global.CHECKNULL(mydata.msgId)===true) ? null : mydata.msgId;
        if (mycmd !== LavieCmd.CMD_LOGIN_SUCCESS) {
            return false;
        }
        const loginData = mydata.data;
        if (Global.CHECKNULL(loginData)===true) {
            return false;
        }
        this.LOGIN_APPID = loginData.appId;
        this.LOGIN_USERID = loginData.userId;
        this.LOGIN_TOKEN = loginData.token;

        return true;
    }
}