import BaseSocket from "./BaseSocket";
import { ValueType } from "./define/Define";
import LavieCmd from "./utils/LavieCmd";
import LavieData from "./utils/LavieData";
import DataBody from "./utils/DataBody";
import { enbuf } from "./protobuff/ProtobufEncoder";
import { debuf } from "./protobuff/ProtobufDecoder";
import EventCenter from "../event/EventCenter";
import EventType from "../event/EventType";
import Global from "../utils/Global";
import LavieEvent from "../event/LavieEvent";

const NetConfig = {
    heartInterval: 10000,
    reconnectDelay: 3000,
    maxReconnectCount: 999999999,
};

enum CloseCode {
    clientClose = 3000,
}

enum SocketStatus {
    connectting,
    reconnectting,
    open,
    closed
}

export default class LavieSocket {

    protected _socket: any = null;
    protected _url: string = '';
    protected _status: SocketStatus = SocketStatus.closed;
    protected _heartbeat = new Heartbeat();
    protected _reconnectCount = 0; //重连次数
    protected _reconnectTimer: any = null; //重连定时器
    protected _connectTimeOutTimer: any = null; //链接超时定时器

    //发起Socket连接
    connet(param: { url: string, onOpen?: () => void }) {
        if (!param.url) {
            console.warn("url cant be null");
            return;
        }

        Global.debugLog('LavieSocket::Event:connect start--->', param.url);

        this._url = param.url;
        this._status = SocketStatus.connectting;
        this._socket = new BaseSocket();
        this._socket.connet({
            url: param.url,
            onOpen: (e: any) => {
                this._status = SocketStatus.open;
                this._heartbeat.setInstance(this);
                this.clearTimer();
                param.onOpen && param.onOpen();
                Global.debugLog('LavieSocket::Event:connect onOpen--->');
                EventCenter.getInstance().dispatchEvent(EventType.TYPE_SOCKET_STATE, LavieEvent.EVENT_SOCKET_OPENED);
            },
            onMessage: (e: any) => {
                this.onMessage(e.data);
            },
            onClose: (e: any) => {
                Global.debugLog('LavieSocket::Event:connect closed--->');
                this._heartbeat.stop();
                if (e.code != CloseCode.clientClose && this._status != SocketStatus.reconnectting) {
                    this.onDisconnect();
                }
                EventCenter.getInstance().dispatchEvent(EventType.TYPE_SOCKET_STATE, LavieEvent.EVENT_SOCKET_CLOSED);
            },
            onError: (e: any) => {
                Global.debugLog('LavieSocket::Event:connect error--->', e);
                this._heartbeat.stop();
                if (this._status != SocketStatus.reconnectting) {
                    this.onDisconnect();
                }
                EventCenter.getInstance().dispatchEvent(EventType.TYPE_SOCKET_STATE, LavieEvent.EVENT_SOCKET_ERROR);
            }
        });

        //连接超时-定时重连（5秒）
        this.scheduleConnecttinTimeOut();
    }

    //发送数据
    send(msgId: string, dataJson: string) {
        if (Global.CHECKNULL(msgId) === true) {
            Global.debugLog("LavieSocket::Error:socket msg id cant be null");
            return;
        }
        if (Global.CHECKNULL(dataJson) === true) {
            Global.debugLog("LavieSocket::Error:socket data cant be null");
            return;
        }
        const protobufData: enbuf.ProtobufData = new enbuf.ProtobufData();
        protobufData.cmd = msgId;
        protobufData.msg = dataJson;

        let dataPackageBuff = enbuf.ProtobufData.encode(protobufData).finish();
        if (dataPackageBuff === null) {
            Global.debugLog("LavieSocket::socket send failed: encode error::", protobufData);
            return;
        }

        let result = this._socket.send(dataPackageBuff);
        // Global.debugLog("LavieSocket::SendData-JSON--->", dataJson);

        if (!this.isHeartPackage(protobufData) && result) {
            Global.debugLog("LavieSocket::SendData-JSON--->", dataJson);
        }
    }

    //收到数据
    onMessage(buff: any) {

        const mydata: debuf.ProtobufData = debuf.ProtobufData.decode(new Uint8Array(buff));

        if (Global.CHECKNULL(mydata) === true) {
            return;
        }
        if (Global.CHECKNULL(mydata.cmd) === true) {
            return;
        }
        const curCmd = mydata.cmd;
        const dataBody: DataBody = new DataBody();
        dataBody.msgId = curCmd;
        dataBody.parseJson(mydata.msg);

        if (this.isHeartPackage(mydata)) {
            Global.debugLog('Heartbeat packet received------>', mydata);
            this._heartbeat.reciveHeart();
            return;
        }

        //登录成功
        if (curCmd === `${LavieCmd.CMD_LOGIN_SUCCESS}`) {
            LavieData.login(dataBody);
            //启动心跳包
            this._heartbeat.start();
            Global.debugLog('LavieSocket::Receive-login success. Start heart beat->');
            EventCenter.getInstance().dispatchEvent(EventType.TYPE_ROOM_MESSAGE, LavieCmd.CMD_LOGIN_SUCCESS, {});
            return;
        }

        if (curCmd === LavieCmd.RESP_ROOM_DATA) {
            const roomData: any = dataBody.data;
            const curMsgId = roomData.msgType;
            const msgData: any = this.onParsePackage(roomData);
            //分发数据
            Global.debugLog('LavieSocket::onMessage:receive-data--->', roomData);
            EventCenter.getInstance().dispatchEvent(EventType.TYPE_ROOM_MESSAGE, curMsgId, msgData);
        }
    }

    onParsePackage(msgData: any) {
        if (Global.CHECKNULL(msgData) === true) {
            return null;
        }
        const content = msgData.content;
        if (Global.CHECKNULL(content) === true) {
            return null;
        }
        let contJson = null;
        try{
            contJson = JSON.parse(content);
        }catch(e:any){
            contJson = null;
        }
        if (Global.CHECKNULL(contJson) === true) {
            return null;
        }
        return contJson;
    }

    //心跳超时
    onHeartTimeOut() {
        this._heartbeat.stop();
        this.closeSocket();
        this.onDisconnect();

        // EventCenter.getInstance().dispatchEvent(EventType.TYPE_SOCKET_STATE, LavieEvent.EVENT_HEART_TIMEOUT);
    }

    //发送心跳包
    sendHeartPackage() {
        const heartPackage = {
            userId: Global.LOGIN_USERID,
            appId: Global.LOGIN_APPID
        }
        Global.debugLog('发送心跳包----->');
        this.send(LavieCmd.REQ_HEART_SEND, JSON.stringify(heartPackage));
    }

    //是否心跳包
    isHeartPackage(mydata: any) {
        if (Global.CHECKNULL(mydata) === true) {
            return false;
        }
        if (Global.CHECKNULL(mydata.cmd) === true) {
            return false;
        }
        const mycmd = mydata.cmd;
        if (`${mycmd}` === `${LavieCmd.CMD_HEART_PACKAGE}`) {
            return true;
        }
        return false;
    }

    //关闭当前连接
    close(code?: number, reason?: string) {
        if (this._reconnectCount > 0) {
            this._reconnectCount = 0;
        }
        this.closeSocket(code, reason);
    }

    //断开或失败，定时重连
    scheduleReconnect() {
        this._reconnectTimer && clearTimeout(this._reconnectTimer);
        this._reconnectTimer = setTimeout(() => {
            this._reconnectTimer = null;
            this.reconnect();
        }, NetConfig.reconnectDelay);
    }

    //连接超时重连
    scheduleConnecttinTimeOut() {
        this._connectTimeOutTimer && clearTimeout(this._connectTimeOutTimer);
        this._connectTimeOutTimer = setTimeout(() => {
            this.closeSocket();
            this.onDisconnect();
        }, NetConfig.heartInterval);
    }

    //停止所有定时器
    clearTimer() {
        this._reconnectTimer && clearTimeout(this._reconnectTimer);
        this._connectTimeOutTimer && clearTimeout(this._connectTimeOutTimer);
        this._reconnectTimer = null;
        this._connectTimeOutTimer = null;
    }

    //链接异常断开
    onDisconnect() {
        if (this._status == SocketStatus.reconnectting) {
            Global.debugLog("LavieSocket::aleardy in reconnecting...");
            return;
        }

        this.clearTimer();
        Global.debugLog("LavieSocket::onDisconnect:Count--->", { disconnect_count: this._reconnectCount });

        this._status = SocketStatus.reconnectting;
        if (this._reconnectCount == 0) {
            this.reconnect();
        }
        else if (this._reconnectCount < NetConfig.maxReconnectCount) {
            this.scheduleReconnect();
        }
        else {
            this._status = SocketStatus.closed;
            this._reconnectCount = 0;
        }
    }

    closeSocket(code?: number, reason?: string) {
        code = typeof code == ValueType.number ? code : CloseCode.clientClose;
        const myReason = (reason === null || reason === undefined) ? 'socket close' : reason;
        this._socket && this._socket.close(code, myReason);
        this._socket = null;
        this._status = SocketStatus.closed;
        this._heartbeat.stop();
        this.clearTimer();

        Global.debugLog("LavieSocket::client closed socket...");
    }

    reconnect() {
        this._reconnectCount++;
        this.connet({
            url: this._url,
            onOpen: () => {
                console.log("reconnect success")
                Global.debugLog("LavieSocket::client closed socket...");
                this._reconnectCount = 0;
                //重连成功自动登录
                EventCenter.getInstance().dispatchEvent(EventType.TYPE_SOCKET_STATE, LavieEvent.EVENT_SOCKET_OPENED);
            }
        })
    }

}

//心跳包
class Heartbeat {
    private _heartHandle: any;
    private _recived = false;
    private _lavieSocket: any = null;

    heartInterval = NetConfig.heartInterval;

    setInstance(_socket: LavieSocket) {
        this._lavieSocket = _socket;
    }

    start() {
        this.stop();
        this._lavieSocket.sendHeartPackage();

        //心跳计时器
        this._heartHandle = setInterval(() => {
            if (!this._recived) {
                this._lavieSocket.onHeartTimeOut();
                return;
            }

            this._lavieSocket.sendHeartPackage();
            this._recived = false;

        }, this.heartInterval);
    }

    stop() {
        this._heartHandle && clearInterval(this._heartHandle);
        this._heartHandle = null;
        this._recived = false;
    }

    reciveHeart() {
        this._recived = true;
    }
}
