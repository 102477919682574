export enum ValueType {
    object = "object",
    string = "string",
    number = "number",
    boolean = "boolean",
    function = "function",
}

export const InvalidValue = {
    minus_1: -1,
    zero: 0,
}


export enum ZIdx{
    zero, 
    mask,
    dialog,
    top,
}

export let TimeFormat = {
    s: 0,
    ms: 1,
    hms: 2,
    dhm: 3,
};

export const Message = {
    addLayer: "addLayer",
    removeLayer: "removeLayer",
    replaceLayer: "replaceLayer",
    popLayer: "popLayer",
    clearLayers: "clearLayers",

    baseMsgType: "Message",
    gameMsgType: "gameMsgType",

    reconnectSuccess: "reconnectSuccess",
}




