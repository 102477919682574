// Import here Polyfills if needed. Recommended core-js (npm i -D core-js)
// import "core-js/fn/array.find"
// ...
import EventCenter from './event/EventCenter';
import EventType from './event/EventType';
import LavieEvent from './event/LavieEvent';
import LavieSocket from './net/LavieSocket';
import LavieCmd from './net/utils/LavieCmd';
import Global from './utils/Global';

interface SocketEvents {
  onSuccess: () => void;
  onClosed: () => void;
  onDataMessage: () => void;
}

class LavieRoom {

  mySocket: LavieSocket = new LavieSocket();
  loginUser: any = null;

  static config: any = null;

  /**
   * init socket sdk
   * @param host 
   * @param port 
   * @returns response
   */
  onInit = () => {
    if (Global.CHECKNULL(Global.WSS_HOST)) {
      return { code: -1, msg: 'The host cannot be null' };
    }
    if (Global.CHECKNULL(Global.WSS_PORT)) {
      return { code: -2, msg: 'The port cannot be null' };
    }
    if (Global.WSS_PORT > 65535) {
      return { code: -3, msg: 'The port is invalid' };
    }
    if (Global.CHECKNULL(this.mySocket)) {
      this.mySocket = new LavieSocket();
    }
    return { code: 200, msg: 'Success' };
  }

  onSocketStateHandle = (e: any) => {
    const myMsgId: number = e.msgId;
    Global.debugLog('LavieRoom::socket state changed--->', e);

    //如果连接（重连）成功
    if (myMsgId === LavieEvent.EVENT_SOCKET_OPENED) {
      this.handleAutoLogin();
    }

  }

  //登录成功
  loginSuccessHandle = (mydata: any) => {
    if (Global.CHECKNULL(mydata) === true) {
      Global.debugLog('LavieRoom::login data is null');
      return;
    }
    const token = mydata.token;
    if (Global.CHECKNULL(token) === true) {
      Global.debugLog('LavieRoom::login token is null');
      return;
    }
    this.loginUser = mydata;
    const usinfo = mydata.userInfo;
    if (Global.CHECKNULL(usinfo) === true) {
      Global.debugLog('LavieRoom::login user is null');
      return;
    }
    Global.LOGIN_DEVICE = mydata.device;
    Global.LOGIN_TOKEN = mydata.token;
    Global.LOGIN_USERID = usinfo.userId;
    Global.LOGIN_UID = usinfo.id;
    Global.debugLog('LavieRoom::login success...', mydata);
    //登录成功
    this.addSocketListener();
    //开始连接socket
    this.connectSocket();
  }

  addSocketListener = () => {
    EventCenter.getInstance().addEventListener(EventType.TYPE_SOCKET_STATE, this.onSocketStateHandle);
  }

  getLoginUser = () => {
    const mydata: any = this.loginUser;
    if (Global.CHECKNULL(mydata) === true) {
      return null;
    }
    const usinfo = mydata.userInfo;
    if (Global.CHECKNULL(usinfo) === true) {
      return null;
    }
    return usinfo;
  }

  //连接成功后自动发起登录
  handleAutoLogin = () => {
    const mydata: any = this.loginUser;
    if (Global.CHECKNULL(mydata) === true) {
      Global.debugLog('LavieRoom::login data is null');
      return;
    }
    Global.debugLog('LavieRoom::auto login...', mydata);
    const params = {
      appId: Global.LOGIN_APPID,
      userId: Global.LOGIN_USERID,
      token: Global.LOGIN_TOKEN
    };
    this.sendLavieMessage(LavieCmd.REQ_USER_LOGIN, params);
  }

  //发送socket消息
  sendLavieMessage = (reqCmd: string, data?: any) => {
    if (Global.CHECKNULL(this.mySocket) === true) {
      return;
    }
    if (Global.CHECKNULL(reqCmd) === true) {
      return;
    }
    const mydata: any = (Global.CHECKNULL(data) === true) ? {} : data;
    this.mySocket.send(reqCmd, JSON.stringify(mydata));
  }

  //发我socket连接
  connectSocket = () => {
    if (Global.CHECKNULL(this.mySocket) === true) {
      Global.debugLog('LiveRoom::The socket host config error!');
      return;
    }
    let socketHead = 'ws://';
    const protocol = window.location.protocol;
    //自动适配wss协议
    if (!Global.CHECKNULL(protocol) && Global.AUTO_WSS) {
      if (protocol === 'https:') {
        socketHead = 'wss://';
      }
    }
    let socketHost: string = `${socketHead}${Global.WSS_HOST}:${Global.WSS_PORT}`;
    if (Global.WSS_PORT <= 0) {//不需要端口
      socketHost = `${socketHead}${Global.WSS_HOST}`;
    } 
    const myconfig: any = {};
    myconfig.url = socketHost;
    this.mySocket.connet(myconfig);
    Global.debugLog('LiveRoom::The socket start connect... ', myconfig);
  }

}

export default LavieRoom;
