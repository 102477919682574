import Global from '../utils/Global';

export default class NetService {

    static ACCESS_TOKEN = 'C9E373C3F8288DD4769BC7E4CE8B007F';

    //post 请求
    static post = async (appid:string, module:string, method:string, $params: any = {}) => {
        return new Promise((resolve, reject) => {
            const myauth = {
                appId: `${appid}`,
                module: `${module}`,
                method: `${method}`,
                publicKey: Global.PUBLIC_KEY
            };
            const baseParams = this.getBaseParams();
            const business = { ...$params };
            const params = NetService.getReqParams(business);
            let request = NetService.getHttpRequest(params, myauth);
            request.onreadystatechange = () => {
                if (request.readyState == 4 && (request.status >= 200 && request.status < 400)) {
                    const response = request.responseText;
                    const dataJson = NetService.parseResponse(response);
                    if (dataJson === null || dataJson === undefined) {
                        reject(dataJson);
                        return;
                    }
                    resolve(dataJson);
                }
            };
            request.send(JSON.stringify(params));
        });
    }

    //第三方设备登录
    static thirdAuth = async (device: string) => {

        return new Promise((resolve, reject) => {
            const myauth = {
                appId: '1MpWQ5ih8aZaC/FKd/OctA==',
                module: 'imRpaZL4yTriWyi8tvCnYw==',
                method: '2wZorkpGCfjNqhfilsO+aw==',
                publicKey: Global.PUBLIC_KEY
            };
            const baseParams = this.getBaseParams();
            baseParams.device = `${device}`;

            baseParams.userId = undefined;
            baseParams.deviceId = undefined;

            const business = {
                ...baseParams,
                sign: Global.getRequestSign(),
                accountType: 7,
                accessToken: NetService.ACCESS_TOKEN
            };

            const params = NetService.getReqParams(business);

            let request = NetService.getHttpRequest(params, myauth);
            request.onreadystatechange = () => {
                if (request.readyState == 4 && (request.status >= 200 && request.status < 400)) {
                    const response = request.responseText;
                    const dataJson = NetService.parseResponse(response);
                    if (dataJson === null || dataJson === undefined) {
                        reject(dataJson);
                        return;
                    }
                    resolve(dataJson);
                }
            };
            request.send(JSON.stringify(params));
        });
    };

    static queryPopularRoomList = async (pageNo: number = 1) => {
        return new Promise((resolve, reject) => {
            const myauth = {
                appId: '7qLgx9aqKPY5dppxw1fy5F3189g+gWmwJEOOEtWjM7c=',
                module: '+uFSlUiqYwcotWJU8sAF6ZdDojFnRX80SFiQn0UpfZ8=',
                method: 'vzFb1Yl5Ub2XBri8oqJfr8/pRNfPUpk4z+8X/LjF59s=',
                publicKey: Global.PUBLIC_KEY
            };
            const baseParams = this.getBaseParams();

            const business = {
                ...baseParams,
                queryFlag: 6, //6-仅返回live房
                pageNo: pageNo,
                pageSize: 30
            };

            const params = NetService.getReqParams(business);

            let request = NetService.getHttpRequest(params, myauth);
            request.onreadystatechange = () => {
                if (request.readyState == 4 && (request.status >= 200 && request.status < 400)) {
                    const response = request.responseText;
                    const dataJson = NetService.parseResponse(response);
                    if (dataJson === null || dataJson === undefined) {
                        reject(dataJson);
                        return;
                    }
                    resolve(dataJson);
                }
            };
            request.send(JSON.stringify(params));
        });
    }

    static enterLiveRoom = async ($roomId: number, $password?: string) => {
        return new Promise((resolve, reject) => {
            const myauth = {
                appId: 'DS0HKNBMAbriG25GtT007A==',
                module: '+uFSlUiqYwcotWJU8sAF6ZdDojFnRX80SFiQn0UpfZ8=',
                method: 'Sxlohbhud4s6DasmDsAtOA==',
                publicKey: Global.PUBLIC_KEY
            };
            const baseParams = this.getBaseParams();

            const business = {
                ...baseParams,
                cname: `${$roomId}_1`,
                roomType: 2, //2-live直播
                streamType: 1, //流类型
                uid: Global.LOGIN_UID,
                password: (Global.CHECKNULL($password) === true) ? '' : $password, //房间密码
                expectType: 3 //期望加入的房间类型, 0-chat 1-dating, 2-cp, 3-live
            };

            const params = NetService.getReqParams(business);

            let request = NetService.getHttpRequest(params, myauth, 'enterRoom');
            request.onreadystatechange = () => {
                if (request.readyState == 4 && (request.status >= 200 && request.status < 400)) {
                    const response = request.responseText;
                    const dataJson = NetService.parseResponse(response);
                    if (dataJson === null || dataJson === undefined) {
                        reject(dataJson);
                        return;
                    }
                    resolve(dataJson);
                }
            };
            request.send(JSON.stringify(params));
        });
    }

    static leaveLiveRoom = async ($roomId: number) => {
        return new Promise((resolve, reject) => {
            const myauth = {
                appId: 'aAa7/TryOCFjpioztEcnKg==',
                module: '+uFSlUiqYwcotWJU8sAF6ZdDojFnRX80SFiQn0UpfZ8=',
                method: '6iPyT0gCmI5S2eMvn1DFgA==',
                publicKey: Global.PUBLIC_KEY
            };
            const baseParams = this.getBaseParams();

            const business = {
                ...baseParams,
                cname: `${$roomId}_1`,
                roomType: 2, //2-live直播
                streamType: 1, //流类型
                uid: Global.LOGIN_UID,
            };

            const params = NetService.getReqParams(business);

            let request = NetService.getHttpRequest(params, myauth, 'leaveRoom');
            request.onreadystatechange = () => {
                if (request.readyState == 4 && (request.status >= 200 && request.status < 400)) {
                    const response = request.responseText;
                    const dataJson = NetService.parseResponse(response);
                    if (dataJson === null || dataJson === undefined) {
                        reject(dataJson);
                        return;
                    }
                    resolve(dataJson);
                }
            };
            request.send(JSON.stringify(params));
        });
    }

    static sendPublicChatMsg = async ($roomId: number, $msg: string, $usinfo:any) => {
        return new Promise((resolve, reject) => {
            const myauth = {
                appId: 'O2AywWne7S2Ro836xpPNow==',
                module: '+uFSlUiqYwcotWJU8sAF6ZdDojFnRX80SFiQn0UpfZ8=',
                method: 'EWNLzSLoi1qugQ7eH6Mbmw==',
                publicKey: Global.PUBLIC_KEY
            };
            const baseParams = this.getBaseParams();
            const userInfo:any = (Global.CHECKNULL($usinfo)===true)?{}:$usinfo;

            const chatmsg = {
                msgType: 1,
                content: `${$msg}`,
                roomId: $roomId,
                userName: Global.ParseNull(userInfo.nickname),

                level: (Global.CHECKNULL(userInfo.level)===true)?0:userInfo.level,
                status: (Global.CHECKNULL(userInfo.status)===true)?0:userInfo.status,
                sex: (Global.CHECKNULL(userInfo.sex)===true)?0:userInfo.sex,
                role : Global.ParseNull(userInfo.role, ''),
                headImg: Global.ParseNull(userInfo.role, ''),
                senderAppId: Global.LOGIN_APPID,
                senderUserId: Global.LOGIN_USERID,
                userId: Global.LOGIN_USERID
            };

            const business = {
                ...baseParams,
                roomId: $roomId,
                msg: JSON.stringify(chatmsg)
            };

            const params = NetService.getReqParams(business);

            let request = NetService.getHttpRequest(params, myauth, 'sendMsg');
            request.onreadystatechange = () => {
                if (request.readyState == 4 && (request.status >= 200 && request.status < 400)) {
                    const response = request.responseText;
                    const dataJson = NetService.parseResponse(response);
                    if (dataJson === null || dataJson === undefined) {
                        reject(dataJson);
                        return;
                    }
                    resolve(dataJson);
                }
            };
            request.send(JSON.stringify(params));
        });
    }

    static queryRoomSeatInfoList = async ($roomId: number) => {
        return new Promise((resolve, reject) => {
            const myauth = {
                appId: 'xN+nqMsoYnf7NoVRvHT9UZTRYwCuy+d+sxOFlqNNpRo=',
                module: '+uFSlUiqYwcotWJU8sAF6ZdDojFnRX80SFiQn0UpfZ8=',
                method: 'ge1rsNeCzEZ21AENWrYZzmL2fVu7SsvOXXDBMA7ba9k=',
                publicKey: Global.PUBLIC_KEY
            };
            const baseParams = this.getBaseParams();

            const business = {
                ...baseParams,
                roomId: $roomId,
            };

            const params = NetService.getReqParams(business);

            let request = NetService.getHttpRequest(params, myauth);
            request.onreadystatechange = () => {
                if (request.readyState == 4 && (request.status >= 200 && request.status < 400)) {
                    const response = request.responseText;
                    const dataJson = NetService.parseResponse(response);
                    if (dataJson === null || dataJson === undefined) {
                        reject(dataJson);
                        return;
                    }
                    resolve(dataJson);
                }
            };
            request.send(JSON.stringify(params));
        });
    }

    static queryOnlineRoomUserList = async ($roomId: number, $pageNo: number = 1) => {
        return new Promise((resolve, reject) => {
            const myauth = {
                appId: 'pOAQS5r2gX75/LcVJ8+MSCbX/dLUiPANRVEASdufGbM=',
                module: '+uFSlUiqYwcotWJU8sAF6ZdDojFnRX80SFiQn0UpfZ8=',
                method: 'fzsHR2eYANGZxPcgvSEYO4NAJno5sHSwEgFRdz0zTLo=',
                publicKey: Global.PUBLIC_KEY
            };
            const baseParams = this.getBaseParams();

            const business = {
                ...baseParams,
                roomId: $roomId,
                pageNo: $pageNo,
                pageSize: 30
            };

            const params = NetService.getReqParams(business);

            let request = NetService.getHttpRequest(params, myauth);
            request.onreadystatechange = () => {
                if (request.readyState == 4 && (request.status >= 200 && request.status < 400)) {
                    const response = request.responseText;
                    const dataJson = NetService.parseResponse(response);
                    if (dataJson === null || dataJson === undefined) {
                        reject(dataJson);
                        return;
                    }
                    resolve(dataJson);
                }
            };
            request.send(JSON.stringify(params));
        });
    }

    static queryLiveAgoraAppId = async () => {
        return new Promise((resolve, reject) => {
            const myauth = {
                appId: 'lyJHmzDFzXop45Rdq6/toQ==',
                module: 'w6JwH6Xl2qZJX9J3UlT50A==',
                method: 'AWyoA0x7N8I0I1nl8hFBG/hIwl3gzkPFk7OAtGyU9c4=',
                publicKey: Global.PUBLIC_KEY
            };
            const baseParams = this.getBaseParams();

            const business = {
                ...baseParams,
                appId: Global.LOGIN_APPID,
                key: 'union_live_agora_appid',
                biz: 0,
            };

            const params = NetService.getReqParams(business);

            let request = NetService.getHttpRequest(params, myauth, '');
            request.onreadystatechange = () => {
                if (request.readyState == 4 && (request.status >= 200 && request.status < 400)) {
                    const response = request.responseText;
                    const dataJson = NetService.parseResponse(response);
                    if (dataJson === null || dataJson === undefined) {
                        reject(dataJson);
                        return;
                    }
                    resolve(dataJson);
                }
            };
            request.send(JSON.stringify(params));
        });
    }

    //*************************************************************** */

    static getBaseParams() {
        const authParams: any = {
            productId: Global.LOGIN_PRODID,
            packageAppId: Global.LOGIN_APPID,
            appId: Global.LOGIN_APPID,
            userId: Global.LOGIN_USERID,
            appKey: Global.BODY_APPKEY,
            deviceId: Global.LOGIN_DEVICE
        };
        return authParams;
    }

    static parseResponse(response: any) {
        let dataJson = null;
        try {
            dataJson = JSON.parse(response);
        } catch (e) {
            console.log('NetServer:Json parse error->');
            return null;
        }

        if (dataJson.code != 200) {
            console.log('NetServer::request failed-->', dataJson);
            return dataJson;
        }
        return dataJson;
    }

    static getReqParams(_business: any) {
        const reqparams = {
            baseParams: {
                appId: Global.LOGIN_APPID,
                lang: Global.LANGUAGE
            },
            business: { ..._business },
            userAuth: {
                appId: Global.LOGIN_APPID,
                userId: Global.LOGIN_USERID,
                token: Global.LOGIN_TOKEN
            }
        }
        if (_business === null || _business === undefined) {
            return reqparams;
        }
        reqparams.business = { ..._business };
        return reqparams;
    };

    static getHttpRequest(_params: any, _auth: any, $method:string = '') {

        const _appId = _auth.appId;
        const _module = _auth.module;
        const _method = $method;

        let request = new XMLHttpRequest();
        const params = (_params === null || _params === undefined) ? {} : _params;
        const encryptMethod = _auth.method;
        const signParams = {
            module: `${_module}`,
            method: `${_method}`,
            timestamp: (new Date()).valueOf(),
            rpcType: 'dubbo',
            content: JSON.stringify(params)
        };
        const mySign = Global.getReqAppSign(signParams);
        request.open("POST", Global.API_DOMAIN, true);
        request.setRequestHeader('Content-Type', 'application/json');
        request.setRequestHeader('module', signParams.module);
        request.setRequestHeader('appId', `${_appId}`);
        request.setRequestHeader('appVersion', '1');
        request.setRequestHeader('appkey', Global.SIGN_APPKEY);
        request.setRequestHeader('method', encryptMethod);
        request.setRequestHeader('rpcType', signParams.rpcType);
        request.setRequestHeader('sign', mySign);
        request.setRequestHeader('publicKey', Global.PUBLIC_KEY);
        request.setRequestHeader('timestamp', `${signParams.timestamp}`);

        return request;
    }
    //end
}
