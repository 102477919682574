import NetUtils from "./NetUtils";

class MyUtils {

  static DEBUG: boolean = MyUtils.isDebugEnvironment();
  static API_HOST: string = ''; //默认，loginInit()跟进appId更新

  //BAAT [印度测试,印度线上] 
  static BAAT_INDIA_APPLIST: string[] = ['8ec6060e-418b-4e4c-b464-bc9b46f32220', '4870cef8-2135-46ab-9ef7-34ac1e6e8391'];
  //BAAT [中东测试,中东线上]
  static BAAT_ARABIC_APPLIST: string[] = ['86739b14-9eed-45d2-8709-53c3adc79d11', 'ef96349e-dcd0-4e2a-8361-62c2b881047b'];
  //WAFA [印度测试,印度线上] 
  static WAFA_INDIA_APPLIST: string[] = ['bff59158-edc6-4beb-b183-3bf6631e8d0a', 'f614034c-c7a2-486c-a87b-19c88fa635d3'];
  //WAFA [中东测试,中东线上]
  static WAFA_ARABIC_APPLIST: string[] = ['6a9e8345-7b7c-4255-9b23-1fba52de11c8', '6a9e8345-7b7c-4255-9b23-1fba5abcdefg'];
  //TAKA LITE [测试，线上]
  static TAKA_LITE_APPLIST: string[] = ['97d6a594-2271-4c4b-94a2-92e61bd8f411', '78e48b09-aeae-4604-9c62-a4ccce5e0dae'];

  static parseNull(val: any, mark: string = '-') {
    if (val === null || val === undefined || val === '') {
      return mark;
    }
    return val;
  }

  static isNull(val: any) {
    if (val === null || val === undefined || val === '') {
      return true;
    }
    return false;
  }

  //解析诸如 12,12 类样式配置
  static parseFormatNums(val: string) {
    let spaceNums: number[] = [0, 0];
    if (MyUtils.isNull(val)) { return spaceNums; }
    const isformat = `${val}`.match(/,/g);
    if (!isformat) { return spaceNums; }
    const spaceList = `${val}`.split(',');
    if (spaceList.length <= 1) { return spaceNums; }
    let upDownNum = 0; let leftRight = 0;
    try {
      upDownNum = parseInt(spaceList[0]);
      leftRight = parseInt(spaceList[1]);
    } catch (e: any) {
      upDownNum = 0; leftRight = 0;
    }
    return [upDownNum, leftRight];
  }

  static parseStyleHeight(val: any) {
    if (MyUtils.isNull(val) || val === 1) {
      return '100vh';
    }
    if (val <= 0) { return undefined; }
    return val;
  }

  static parseStyleWidth(val: any) {
    if (MyUtils.isNull(val) || val <= 0) {
      return '100%';
    }
    return parseInt(val);
  }

  //域名判断|保底
  static isDebugEnvironment() {
    const adminHost: string = window.location.host;
    console.log('MyUtils::isDebugEnvironment-->', adminHost);
    if (this.isNull(adminHost)) { return true; }

    const debugHosts = ['video-qa.lavideo.vip', 'video-qa.hayyaapp.com', 'localhost', '192.168.1'];
    let isdebug = false;
    for (var i = 0; i < debugHosts.length; i++) {
      if (adminHost.search(debugHosts[i]) >= 0) {
        isdebug = true;
        break;
      }
    }
    console.log('MyUtils::isDebugEnvironment-> DEBUG:', isdebug);
    return isdebug;
  }

  static getUrlParams(par: any) {
    if (par == '')
      return '';
    var reg = new RegExp("(^|&)" + par + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null)
      return r[2];

    return '';
  }

  // static getApiDomain() {
  //   const pageHost = window.location.host;
  //   if (MyUtils.DEBUG === true) {
  //     return '//api-qa.girlsmm.com';
  //   }
  //   if (pageHost.search('video-qa') >= 0 || pageHost.search('localhost') >= 0 || pageHost.search('192.168.') >= 0) {
  //     return '//api-qa.girlsmm.com';
  //   }
  //   const apiHost = pageHost.replace('video', 'api');
  //   return `https://${apiHost}`;
  // }

  //兼容后台编辑器-采用AppID判断
  static getApiDomain() {
    //测试环境
    if (MyUtils.DEBUG === true) {
      return '//api-qa.hayyaapp.com';
    }
    //Wafa印度
    if (MyUtils.WAFA_INDIA_APPLIST.includes(NetUtils.LOGIN_APPID)) {
      return '//api.hayyamm.com';
    }
    //Wafa印度
    if (MyUtils.WAFA_ARABIC_APPLIST.includes(NetUtils.LOGIN_APPID)) {
      return '//api.hayyaapp.com';
    }
    //Baat印度
    if (MyUtils.BAAT_INDIA_APPLIST.includes(NetUtils.LOGIN_APPID)) {
      return '//api.baatlive.com';
    }
    //Baat中东
    if (MyUtils.BAAT_ARABIC_APPLIST.includes(NetUtils.LOGIN_APPID)) {
      return '//api.takaglobals.com';
    }
    //Takalite
    if (MyUtils.TAKA_LITE_APPLIST.includes(NetUtils.LOGIN_APPID)) {
      return '//api.takalite.com';
    }
    //VM平台
    return 'https://api.girlsmm.com';
  }

  static closePage() {
    const $window: any = window;
    if (this.isNull($window)) {
      return;
    }
    const mobilejs = $window.mobilejs;
    if (mobilejs && mobilejs.close) {
      mobilejs.close();
    }
  }

  static handleSendMessage(userInfo: any) {
    if (this.isNull(userInfo)) {
      return;
    }
    const $window: any = window;
    if (this.isNull($window)) {
      return;
    }
    const mobilejs = $window.mobilejs;
    if (mobilejs && mobilejs.handleSendMessage) {
      const myInfo = {
        uid: userInfo.uid,
        nickname: userInfo.nickname,
        headImg: userInfo.headImg
      };
      mobilejs.handleSendMessage(JSON.stringify(myInfo));
    }
  }

  static numFormat(num: number, digits: number) {
    const si = [
      { value: 1, symbol: "" },
      { value: 1E3, symbol: "K" },
      { value: 1E6, symbol: "M" },
      { value: 1E9, symbol: "G" },
      { value: 1E12, symbol: "T" },
      { value: 1E15, symbol: "P" },
      { value: 1E18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  }
}

export default MyUtils;